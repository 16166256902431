import React                                   from 'react'
import ReactDOM                                from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import routes                                  from './routes'
import reportWebVitals                         from './reportWebVitals'
import './assets/App.scss'
import Theme                                   from './layout/Theme'

const router = createBrowserRouter(routes)

const root = ReactDOM.createRoot(document.getElementById('root')  as HTMLElement)
root.render(
    <React.StrictMode>
      <React.StrictMode>
        <Theme>
          <RouterProvider router={router} />
        </Theme>
      </React.StrictMode>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
