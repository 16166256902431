import React, { useEffect, useState }                     from 'react'
import { ConfigProvider, theme } from 'antd'
import themeSettings             from '../settings/theme.json'

const Theme:React.FC<{children:React.ReactNode}> = ({children}) => {
  const useLight        = themeSettings.mode.light
  const storageMode     = localStorage.getItem("themeMode")
  const [mode, setMode] = useState<'light' | 'dark'>(useLight ? 'light' : 'dark')

  useEffect (() => {
    setTheme()
    window.addEventListener('theme', ()=>{ setTheme() })
    // eslint-disable-next-line
  }, [])

  const setTheme = () => {
    const storageMode = localStorage.getItem("themeMode")
    if      (storageMode === 'light') { setMode('light')                     }
    else if (storageMode === 'dark')  { setMode('dark')                      }
    else                              { setMode(useLight ? 'light' : 'dark') }
  }
  const activeTheme = () => {
    if      (storageMode === 'light') { return theme.defaultAlgorithm }
    else if (storageMode === 'dark')  { return theme.darkAlgorithm }
    return themeSettings.mode.light ? theme.defaultAlgorithm : theme.darkAlgorithm
  }
  return  <>
            <ConfigProvider theme={{ algorithm: activeTheme() }} >
              <div className={`theme-${mode}`}> {children} </div>
            </ConfigProvider>
          </>
}

export default Theme