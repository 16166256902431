import React, { useEffect, useState }  from 'react'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
}                                      from '@ant-design/icons'
import { useNavigate, useLocation  }   from 'react-router-dom'
import { Button, Layout, Menu, theme } from 'antd'
import UserAvatar                      from '../components/user/Avatar'
import Mode                            from '../components/user/Mode'
import Logo                            from '../assets/img/B.png'
import { DashboardMenu }               from '../module/common/interface'

const { Header, Sider, Content, Footer } = Layout;

const Dashboard: React.FC<{children:React.ReactNode}> = ({children}) => {
  // menu status
  const [collapsed, setCollapsed]   = useState<boolean>(false)
  const [activeKeys, setActiveKeys] = useState<string[]>([])


  // layout costants
  const headerHeight   :number  = 64
  const containerMargin:number  = 24
  const totalMargin    :number  = containerMargin
  const footerHeight   :number  = 24
  const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken()

  // navigations costants
  const navigate     = useNavigate()
  const location     = useLocation()
  const { pathname } = location


  useEffect(()=>{
    const active = menuItems.find(item => pathname.replaceAll('/', '') === item.target)
    if (active) {setActiveKeys([active.key])}
    else {setActiveKeys(['dashboard'])}
    // eslint-disable-next-line
  },[pathname])

  const menuItems:DashboardMenu[] = [
    {
      key: 'dashboard',
      icon: <UserOutlined />,
      label: 'Dashboard',
      target: ''
    },
    // {
    //   key: '2',
    //   icon: <VideoCameraOutlined />,
    //   label: 'nav 2',
    //   target: 'page'
    // },
    // {
    //   key: '3',
    //   icon: <UploadOutlined />,
    //   label: 'nav 3',
    //   target: 'a2'
    // },
  ]

  const handleMenuClick = ({ key }:any) => {
    const { target } = menuItems.find(item => item.key === key) || {}
    if (target!==undefined) { navigate(`/${target}`) }
  }
  return (
    <Layout className='dashboard'>
      <Sider trigger={null} collapsible collapsed={collapsed} className='dashboard__sider' breakpoint="md" onBreakpoint={(broken) => { setCollapsed(broken) }} >
        <div className="dashboard__logo-vertical"><img src={Logo} alt="Logo" /></div>
        <Menu
          mode="inline"
          theme="dark"
          onClick={handleMenuClick}
          selectedKeys={activeKeys}
          items={menuItems}
          className='dashboard__sider__menu'
        />
      </Sider>
      <Layout>
        <Header style={{background: colorBgContainer }} className='dashboard__header'>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: headerHeight,
              height: headerHeight,
            }}
          />
          <div className='dashboard__header__settings'>
            <UserAvatar />
            <Mode />
          </div>
        </Header>
        <Content
          style={{
            margin: `${containerMargin}px 16px 0 16px`,
            padding: containerMargin,
            height: `calc(100vh - ${headerHeight + totalMargin + footerHeight}px)`,
            background: colorBgContainer,
            borderRadius: borderRadiusLG
          }}
          className='dashboard__content'
        >
          {children}
        </Content>
        <Footer className='dashboard__footer' style={{height:`${footerHeight}px`}}>
          Dashboard ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Dashboard