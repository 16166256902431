import { Avatar, Dropdown, MenuProps } from 'antd'
import { useNavigate } from 'react-router-dom'

const UserAvatar: React.FC = () => {
  const navigate = useNavigate()
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: ( <a target="_blank" rel="noopener noreferrer" href="#">Settings</a> ),
    },
    {
      key: '2',
      label: ( <a target="_blank" rel="noopener noreferrer" onClick={() => navigate('/login')}>Log out</a> ),
    }
  ]

  return  <Dropdown  menu={{ items }} placement="bottomRight">
            <div className='avatar-content'>
              <Avatar className='avatar-icon' src="https://api.dicebear.com/7.x/miniavs/svg?seed=3" />
              <span className='avatar-name'>Mario Rossi</span>
            </div>
          </Dropdown>
}

export default UserAvatar