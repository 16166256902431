import React, { Suspense, lazy } from 'react'
import Dashboard from './layout/Dashboard'
import type { RouteObject } from 'react-router'

const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) =>
  (

      <Suspense fallback={<></>}>
        <Component {...props} />
      </Suspense>
  );


const Index = Loadable(lazy(() => import('./pages/Home')))
// const Page  = Loadable(lazy(() => import('./pages/Page')))
const Login = Loadable(lazy(() => import('./pages/Login')))

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Login />
  },
  {
    path: '/login',
    element: <Login />

  },
  {
    path: '/dashboard',
    element: <Dashboard><Index/></Dashboard>

  }
]

export default routes